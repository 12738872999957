<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <div class="col-lg-12">  
                <div class="card elevation-1">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mill ID</h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Mill" clearable>
                                </v-autocomplete>
                            </v-col> 
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Material</h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="materialModel" :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Material" clearable >
                                </v-autocomplete>
                            </v-col>  
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Grade</h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="gradeModel" :items="gradeLists" default="" item-value="grade_id" item-text="grade_id" label="Grade" clearable >
                                </v-autocomplete>
                            </v-col>  
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Color</h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="colorModel" :items="colorLists" default="" item-value="NamaWarna" item-text="NamaWarna" label="Color" clearable>
                                </v-autocomplete>
                            </v-col>  
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Thickness</h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true  v-model="thicknessModel" :items="thicknessLists" default="" item-value="thick" :item-text="item => item.thick + ' mm'" label="Thickness" clearable>
                                </v-autocomplete>
                            </v-col> 
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Width</h6>
                                <v-autocomplete
                                    dense 
                                    solo
                                    class="ma-0 pa-1 border-12"
                                    v-model="widthModel" 
                                    :items="widthLists" 
                                    default="" 
                                    item-value="width" 
                                    :item-text="item => item.width + ' mm'" 
                                    label="Width" 
                                    clearable>
                                </v-autocomplete>
                                </v-col>  
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Quality</h6>
                                <v-autocomplete 
                                    dense 
                                    solo 
                                    class="ma-0 pa-1 border-12" 
                                    v-model="qualityModel" 
                                    :items="qualityLists" 
                                    default="" 
                                    item-value="quality_id" 
                                    item-text="quality_id" 
                                    label="Quality" 
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Category</h6>
                                <v-autocomplete 
                                    dense 
                                    solo 
                                    class="ma-0 pa-1 border-12" 
                                    v-model="categoryModel" 
                                    :items="categoryLists" 
                                    default="" 
                                    item-value="category" 
                                    item-text="category" 
                                    label="Category" 
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                    <v-btn class="border-12 mt-7" color="red lighten-5" block  style="padding: 21px;"
                                    elevation="2" small @click="clear()"><v-icon>mdi-window-close</v-icon>Clear</v-btn>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                    <v-btn class="border-12 mt-7" block style="padding: 21px;"
                                    color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 cardResult">
            <v-col cols="12">
                        <v-card outlined class="p-0" >
                            <v-card-text class="mt-0">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="itemLists"
                                    class="elevation-1 mt-0"
                                    :items-per-page="30"
                                    height="300"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                    >  
                                    <template v-slot:top>
                                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                                    <div class="d-flex w-100">
                                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 mt-1" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                        <v-col cols="12" xs="12" sm="12" md="4">
                                                            <div style="width: 50%;">
                                                                <v-sheet
                                                                    class="mx-auto"
                                                                    >
                                                                    <v-card class="p-0 m-0 red darken-4">
                                                                        <v-card-text class="p-3 red darken-4" >
                                                                            <v-row>
                                                                                <v-col cols="12 text-white  text-center pl-1 pr-1 pb-1">
                                                                                    <h7 v-if="totalWgt"> Total WGT  : {{ $store.getters.convertToCurrencyUs(totalWgt) }} TON</h7>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-sheet>
                                                            </div>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                        small
                                                        color="#fff"
                                                        class="py-5 mr-3"
                                                        @click="getData()"
                                                        >
                                                            <v-icon>mdi-cached</v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                        small
                                                        color="#005c37"
                                                        class="py-5 mr-3 text-white"
                                                        >
                                                        <v-btn class="mb-0 mr-2 text-white" 
                                                        color="#005c37" elevation="2" medium>
                                                        <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headersColumn"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        To Excel
                                                        </download-excel> 
                                                        <v-icon>mdi-arrow-down-bold-box</v-icon>
                                                    </v-btn>
                                                        </v-btn>
                                                    </div>
                                                </v-toolbar>
                                            </template> 
                                            <template v-slot:[`item.Tebal`]="{ item }">
                                        {{ item.Tebal + ' mm' }}
                                    </template>
                                    <template v-slot:[`item.matrl_width`]="{ item }">
                                        {{ item.matrl_width + ' mm' }}
                                    </template>
                                    <template v-slot:[`item.panjang`]="{ item }">
                                        {{ item.panjang + ' m' }}
                                    </template>
                                    <template v-slot:[`item.kg_outstanding`]="{ item }">
                                        {{ item.kg_outstanding + ' kg' }}
                                    </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </div>
            <!-- <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="materialModel" :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Select Material" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="gradeModel" :items="gradeLists" default="" item-value="grade_id" item-text="grade_id" label="Select Grade" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="colorModel" :items="colorLists" default="" item-value="NamaWarna" item-text="NamaWarna" label="Select Color" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="thicknessModel" :items="thicknessLists" default="" item-value="thick" :item-text="item => item.thick + ' mm'" label="Select Thickness" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="widthModel" :items="widthLists" default="" item-value="width" :item-text="item => item.width + ' mm'" label="Select Width" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="qualityModel" :items="qualityLists" default="" item-value="quality_id" item-text="quality_id" label="Select Quality" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="categoryModel" :items="categoryLists" default="" item-value="category" item-text="category" label="Select Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                    <v-col cols="12" xs="12" sm="12" md="2">
                            <div style="width: 80%;">
                                <v-sheet
                                    class="mx-auto"
                                    >
                                    <v-card class="p-0 m-0 blue ">
                                        <v-card-text class="p-3 blue" >
                                            <v-row>
                                                <v-col cols="12  white--text text-center pl-1 pr-1 pb-1">
                                                    <h7 v-if="totalWgt"> Total WGT  : {{ $store.getters.convertToCurrencyUs(totalWgt) }} TON</h7>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-sheet>
                            </div>
                    </v-col>
                </div>
            </div> -->
            <!-- <div class="col-lg-12 cardResult">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.Tebal`]="{ item }">
                                    {{ item.Tebal + ' mm' }}
                                </template>
                                <template v-slot:[`item.matrl_width`]="{ item }">
                                    {{ item.matrl_width + ' mm' }}
                                </template>
                                <template v-slot:[`item.panjang`]="{ item }">
                                    {{ item.panjang + ' m' }}
                                </template>
                                <template v-slot:[`item.kg_outstanding`]="{ item }">
                                    {{ item.kg_outstanding + ' kg' }}
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div> -->

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Shortage Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            categoryModel: '',
            categoryLists: [
                { category: 'ROOF', },
                { category: 'TRUSS' },
            ],
            materialModel: '',
            materialLists: [],
            gradeModel: '',
            gradeLists: [],
            qualityModel: '',
            qualityLists: [],
            colorModel: '',
            colorLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            itemLists: [],
            totalWgt:[],
            headers: [
                { text: 'ORD. DATE', value: 'TglPesan', align: 'left' },
                { text: 'ORD.RECEIVED', value: 'dt_rcv', align: 'left' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'left' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left' },
                { text: 'MATERIAL', value: 'material', align: 'left' },
                { text: 'THICKNESS', value: 'Tebal', align: 'left' },
                { text: 'WIDTH', value: 'matrl_width', align: 'left' },
                { text: 'COLOR', value: 'NamaWarna', align: 'left' },
                { text: 'GRADE', value: 'grd', align: 'left' },
                { text: 'QUALITY', value: 'grade', align: 'left' },
                { text: 'LENGTH', value: 'Lebar', align: 'left' },
                { text: 'QTY.', value: 'qty_outstanding', align: 'left' },
                { text: 'TOTAL', value: 'totalMeter_outstanding', align: 'left' },
                { text: 'WGT', value: 'kg_outstanding', align: 'left' },
            ],
            headersColumn: {
            'ORD. DATE':'TglPesan',
            'ORD.RECEIVED': 'dt_rcv',
            'CUSTOMER': 'NamaCustomer',
            'SALESMAN' : 'NamaSales',
            'DESC': 'NamaBarang',
            'MATERIAL': 'material',
            'THICKNESS': 'Tebal',
            'WIDTH': 'matrl_width',
            'COLOR': 'NamaWarna',
            'GRADE': 'grd',
            'QUALITY': 'grade',
            'LENGTH' :'Lebar',
            'QTY' :'qty_outstanding',
            'TOTAL' : 'totalMeter_outstanding',
            'WGT' : 'kg_outstanding',
            },
            loadingDatatable: false,
            searchItem: '',
        }
    },
    async mounted(){
        //this.getTotal()
        this.initialize()
        document.querySelector(".cardResult").style.display = "none";

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/OrderShortage`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.millLists = res.data.mill
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)
            })
        },
    startDownload(){
        this.loading = true
        this.disabledExport=true
    },
    finishDownload(){
        this.loading = false
        this.disabledExport=false
    },    
    clear(){
            this.millModel = ''
            this.categoryModel = ''
            this.materialModel = ''
            this.gradeModel = ''
            this.colorModel = ''
            this.thicknessModel = ''
            this.widthModel = ''
            this.qualityModel = ''
        },
    async exportExcel() {
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/kka/OrderShortage/ExExcel?mill_id=${this.millModel ? this.millModel : ''}&product=${this.categoryModel ? this.categoryModel : ''}&material=${this.materialModel ? this.materialModel : ''}&grade=${this.gradeModel ? this.gradeModel : ''}&color=${this.colorModel ? this.colorModel : ''}&thickness=${this.thicknessModel ? this.thicknessModel : ''}&width=${this.widthModel ? this.widthModel : ''}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
             console.log(response.data);
             return response.data.result
        },   
    async getTotal(){
        await axios.get(`${process.env.VUE_APP_URL}/api/kka/OrderShortage/Total?mill_id=${this.millModel ? this.millModel : ''}&product=${this.categoryModel ? this.categoryModel : ''}&material=${this.materialModel ? this.materialModel : ''}&grade=${this.gradeModel ? this.gradeModel : ''}&color=${this.colorModel ? this.colorModel : ''}&thickness=${this.thicknessModel ? this.thicknessModel : ''}&width=${this.widthModel ? this.widthModel : ''}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                console.log(res.data);
                this.totalWgt = res.data[0].total_wgt; 
                })
                .catch(err => {
                    console.error(err);
                });
            },
    async getData(){
            this.getTotal()
            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/OrderShortage/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    quality: this.qualityModel ? this.qualityModel : '',
                    product: this.categoryModel ? this.categoryModel : '',
                    product: this.productModel ? this.productModel : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            });

        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            }
        },

        updateCustomerDropdown()
        {
            var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.customerLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>